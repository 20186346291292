// How to Sell a Car in.. [States]
const statesNames = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia',
  'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
  'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
  'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
];
const stateAbbreviations = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC',
  'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME',
  'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
  'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI',
  'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
];
// Private Seller Cars in My Area [Cities]
const carsCities = [
  {
    name: 'Atlanta',
    path: 'atlanta-ga',
  },
  {
    name: 'Austin',
    path: 'austin-tx',
  },
  {
    name: 'Charleston',
    path: 'charleston-sc',
  },
  {
    name: 'Charlotte',
    path: 'charlotte-nc',
  },
  {
    name: 'Chicago',
    path: 'chicago-il',
  },
  {
    name: 'Cincinnati',
    path: 'cincinnati-oh',
  },
  {
    name: 'Cleveland',
    path: 'cleveland-oh',
  },
  {
    name: 'Colorado Springs',
    path: 'colorado-springs-co',
  },
  {
    name: 'Columbia',
    path: 'columbia-sc',
  },
  {
    name: 'Columbus',
    path: 'columbus-oh',
  },
  {
    name: 'Dallas',
    path: 'dallas-tx',
  },
  {
    name: 'Denver',
    path: 'denver-co',
  },
  {
    name: 'Detroit',
    path: 'detroit-mi',
  },
  {
    name: 'Fort Myers',
    path: 'fort-myers-fl',
  },
  {
    name: 'Fresno',
    path: 'fresno-ca',
  },
  {
    name: 'Grand Rapids',
    path: 'grand-rapids-mi',
  },
  {
    name: 'Greensboro',
    path: 'greensboro-nc',
  },
  {
    name: 'Greenville',
    path: 'greenville-sc',
  },
  {
    name: 'Hartford',
    path: 'hartford-ct',
  },
  {
    name: 'Houston',
    path: 'houston-tx',
  },
  {
    name: 'Indianapolis',
    path: 'indianapolis-in',
  },
  {
    name: 'Jacksonville',
    path: 'jacksonville-fl',
  },
  {
    name: 'Las Vegas',
    path: 'las-vegas-nv',
  },
  {
    name: 'Los Angeles',
    path: 'los-angeles-ca',
  },
  {
    name: 'Miami',
    path: 'miami-fl',
  },
  {
    name: 'New York City',
    path: 'new-york-ny',
  },
  {
    name: 'Newark',
    path: 'newark-nj',
  },
  {
    name: 'Orlando',
    path: 'orlando-fl',
  },
  {
    name: 'Philadelphia',
    path: 'philadelphia-pa',
  },
  {
    name: 'Phoenix',
    path: 'phoenix-az',
  },
  {
    name: 'Pittsburgh',
    path: 'pittsburgh-pa',
  },
  {
    name: 'Portland',
    path: 'portland-or',
  },
  {
    name: 'Raleigh',
    path: 'raleigh-nc',
  },
  {
    name: 'Richmond',
    path: 'richmond-va',
  },
  {
    name: 'Sacramento',
    path: 'sacramento-ca',
  },
  {
    name: 'Salt Lake City',
    path: 'salt-lake-city-ut',
  },
  {
    name: 'San Antonio',
    path: 'san-antonio-tx',
  },
  {
    name: 'San Diego',
    path: 'san-diego-ca',
  },
  {
    name: 'San Francisco',
    path: 'san-francisco-ca',
  },
  {
    name: 'Seattle',
    path: 'seattle-wa',
  },
  {
    name: 'Tampa',
    path: 'tampa-fl',
  },
  {
    name: 'Virginia Beach',
    path: 'virginia-beach-va',
  },
];
// Private Seller Trucks in My Area [Cities]
const trucksCities = [
  {
    name: 'Arlington',
    path: 'arlington-va',
  },
  {
    name: 'Atlanta',
    path: 'atlanta-ga',
  },
  {
    name: 'Baltimore',
    path: 'baltimore-md',
  },
  {
    name: 'Boston',
    path: 'boston-ma',
  },
  {
    name: 'Chicago',
    path: 'chicago-il',
  },
  {
    name: 'Dallas',
    path: 'dallas-tx',
  },
  {
    name: 'Denver',
    path: 'denver-co',
  },
  {
    name: 'Detroit',
    path: 'detroit-mi',
  },
  {
    name: 'Houston',
    path: 'houston-tx',
  },
  {
    name: 'Los Angeles',
    path: 'los-angeles-ca',
  },
  {
    name: 'Miami',
    path: 'miami-fl',
  },
  {
    name: 'Minneapolis',
    path: 'minneapolis-mn',
  },
  {
    name: 'New York City',
    path: 'new-york-ny',
  },
  {
    name: 'Newark',
    path: 'newark-nj',
  },
  {
    name: 'Philadelphia',
    path: 'philadelphia-pa',
  },
  {
    name: 'Phoenix',
    path: 'phoenix-az',
  },
  {
    name: 'San Diego',
    path: 'san-diego-ca',
  },
  {
    name: 'San Francisco',
    path: 'san-francisco-ca',
  },
  {
    name: 'Seattle',
    path: 'seattle-wa',
  },
  {
    name: 'Tampa',
    path: 'tampa-fl',
  },
];
// Private Seller Cars by Price
const privateSellerCarsLinks = [
  {
    name: 'Cars for Sale by Owner Under $1,000',
    path: '/cars-for-sale/by-owner/cars-under-1000',
  },
  {
    name: 'Cars for Sale by Owner Under $2,000',
    path: '/cars-for-sale/by-owner/cars-under-2000',
  },
  {
    name: 'Cars for Sale by Owner Under $5,000',
    path: '/cars-for-sale/by-owner/cars-under-5000',
  },
  {
    name: 'Cars for Sale by Owner Under $10,000',
    path: '/cars-for-sale/by-owner/cars-under-10000',
  },
  {
    name: 'Cars for Sale by Owner Under $15,000',
    path: '/cars-for-sale/by-owner/cars-under-15000',
  },
  {
    name: 'Cars for Sale by Owner Under $20,000',
    path: '/cars-for-sale/by-owner/cars-under-20000',
  },
  {
    name: 'Cars for Sale by Owner Under $25,000',
    path: '/cars-for-sale/by-owner/cars-under-25000',
  },
  {
    name: 'Cars for Sale by Owner Under $30,000',
    path: '/cars-for-sale/by-owner/cars-under-30000',
  },
];
export {
  statesNames,
  stateAbbreviations,
  carsCities,
  trucksCities,
  privateSellerCarsLinks,
};
